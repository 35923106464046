import { useState, useEffect } from 'react';

import toast from 'react-hot-toast';
import apiCall from '../../helperFunctions/apiCall';
import { getLearner } from '../utilities/auth/authAPI';
import { Loading } from '../utilities/helpers';
import LessonData from './lessonData';
import ScoreData from './scoreData';
import ButtonClose from '../utilities/buttonClose';
import ViewLearnerData from './viewLearnerData';


function ViewLearner(props) {

  // Define variables
  const [learner, setLearner] = useState({});
  const id = props.id;
  const closeModal = props.closeModal;
  const firstName = learner.firstName;


  // Component load actions
  useEffect(() => {

    const payload = {id: id};

    apiCall(getLearner, payload).then((response) => {

      if( response.success ) {
        setLearner(response.data);
      } else {
        toast.error(response.error);
        closeModal();
      }
    });
  }, [id]);


  // Screen componets
  let content = (
    <div>
      <div class='modal-header'>
        <div></div>
        <div>
          <ButtonClose closeAction={closeModal} />
        </div>
      </div>
      <div class='modal-body'>
        <Loading />
      </div>
    </div>
  );


  if( firstName ) {

    let phoneNumberElement;
    if( learner.phoneNumber ) {
      phoneNumberElement = <p><small>{learner.phoneNumber}</small></p>;
    }

    content = (
      <div class='modal-flex-container'>
        <div class='modal-header'>

          <div>
            <h4>{firstName} {learner.lastName}</h4>
            <p>{learner.email}</p>
            {phoneNumberElement}
            <p style={{marginTop: '-3px'}}>
              <small><i>{learner.subscriptionType} Membership</i></small>
            </p>
          </div>

          <div>
            <button
              class='icon icon-printer'
              onClick={() => window.print()}
              title='Print'
              style={{marginRight: '15px'}}
            />

            <ButtonClose closeAction={closeModal} />
          </div>

        </div>

        <div class='modal-body'>
          <div class='stats-container flex-row' style={{marginTop: '30px', marginBottom: '30px'}}>

            <LessonData
              data={learner.lessonOverTimeData}
              lessonsStarted={learner.lessonsStarted}
              lessonsCompleted={learner.lessonsCompleted}
              averageRetries={learner.averageRetries}
              averageScore={learner.averageScore}
            />

            <ScoreData
              data={learner.scoreData}
              score={learner.score}
            />

          </div>

          <ViewLearnerData learner={learner} />
        </div>
      </div>
    );
  }


  return(
    <div class='modal-wrapper'>
      <div class='modal-container'>
        {content}
      </div>
    </div>
  );
}

export default ViewLearner;